var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var aL,bL,cL,dL,wga,eL,fL,gL;aL=function(a,b){this.db=a;this.ui=b;this.C=393216;this.K=0};bL=function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return!($CLJS.oe(a)&&!0===$CLJS.Rj.g(a))};cL=function(a,b,c,d,e,f,g,l,n,q,u,v,x,y,B,H,I,Q,Y){this.form=a;this.gf=b;this.options=c;this.forms=d;this.Y=e;this.Le=f;this.X=g;this.children=l;this.min=n;this.Ef=q;this.Ob=u;this.cc=v;this.parent=x;this.Ij=y;this.Mf=B;this.type=H;this.cache=I;this.max=Q;this.Ti=Y;this.C=393216;this.K=0};
dL=function(a){for(var b=null;;){var c=$CLJS.Xr(a,b);a=c;if(!$CLJS.jp(c))return a}};wga=function(a,b,c){var d=dL($CLJS.Pp.h(a,c));return $CLJS.Vr($CLJS.Uo(d),$CLJS.Ro(d),function(){var e=$CLJS.To(d);return b.g?b.g(e):b.call(null,e)}(),function(){var e=$CLJS.Wr(d);return $CLJS.m(e)?e:c}())};
eL=function(a){return function(b,c,d){var e=$CLJS.A(c);b=$CLJS.C(e);e=$CLJS.D(e);c=$CLJS.Mm.h(function(f){return $CLJS.Pp.h(f,d)},c);return new $CLJS.S(null,3,5,$CLJS.T,[c,$CLJS.Mm.h($CLJS.su,c),$CLJS.Sb(function(f,g){return a.j?a.j(f,g,d):a.call(null,f,g,d)},b,e)],null)}};fL=function(a,b,c,d,e,f,g,l,n){this.Mf=a;this.gf=b;this.type=c;this.min=d;this.max=e;this.Le=f;this.Ob=g;this.cc=l;this.Ef=n;this.C=393216;this.K=0};
gL=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.Uk),d=$CLJS.M.h(b,$CLJS.ok),e=$CLJS.M.h(b,$CLJS.Vl),f=$CLJS.M.h(b,$CLJS.fv),g=$CLJS.M.h(b,$CLJS.Pq),l=$CLJS.M.h(b,$CLJS.Gt);return new fL(a,b,c,d,e,f,g,l,new $CLJS.k(null,1,[$CLJS.Uk,$CLJS.Tq],null))};$CLJS.iL=function(a,b){$CLJS.dj.D(hL,$CLJS.U,a,b);return null};$CLJS.h=aL.prototype;$CLJS.h.O=function(a,b){return new aL(this.db,b)};$CLJS.h.N=function(){return this.ui};$CLJS.h.ge=$CLJS.t;
$CLJS.h.Pd=function(a,b){return $CLJS.Pn($CLJS.Vn($CLJS.r(this.db)),b)};$CLJS.h.Qd=function(){return $CLJS.Qn($CLJS.Vn($CLJS.r(this.db)))};$CLJS.h=cL.prototype;$CLJS.h.O=function(a,b){return new cL(this.form,this.gf,this.options,this.forms,this.Y,this.Le,this.X,this.children,this.min,this.Ef,this.Ob,this.cc,this.parent,this.Ij,this.Mf,this.type,this.cache,this.max,b)};$CLJS.h.N=function(){return this.Ti};$CLJS.h.gb=$CLJS.t;$CLJS.h.Ka=function(){return $CLJS.Oo(this.X)};$CLJS.h.Ta=function(){return this.options};
$CLJS.h.bb=function(a,b,c,d){return $CLJS.uq(this,new $CLJS.S(null,1,5,$CLJS.T,[this.X],null),b,c,d)};$CLJS.h.ib=function(){return this.Y};$CLJS.h.Aa=function(){return this.children};$CLJS.h.ab=function(){return $CLJS.r(this.form)};$CLJS.h.$a=function(a,b){return $CLJS.Po(this.X,b)};$CLJS.h.hb=function(){return this.parent};$CLJS.h.eb=$CLJS.t;$CLJS.h.qa=function(){return this.cache};$CLJS.h.pa=function(a,b,c){return $CLJS.M.j(this.children,b,c)};$CLJS.h.Ze=$CLJS.t;$CLJS.h.wd=function(){return null};
$CLJS.h.ye=function(){return this.X};var jL=function jL(a){switch(arguments.length){case 2:return jL.h(arguments[0],arguments[1]);case 3:return jL.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};jL.h=function(a,b){return jL.j(a,b,null)};jL.j=function(a,b,c){var d=$CLJS.si(b);return wga(a,function(e){return $CLJS.ug(function(f){f=$CLJS.J(f,0,null);return d.g?d.g(f):d.call(null,f)},e)},c)};jL.v=3;
var kL=function kL(a){switch(arguments.length){case 2:return kL.h(arguments[0],arguments[1]);case 3:return kL.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};kL.h=function(a,b){return kL.j(a,b,null)};
kL.j=function(a,b,c){function d(v,x){return $CLJS.F.h($CLJS.Ul,v)?new $CLJS.S(null,2,5,$CLJS.T,[null,x],null):$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ls.g(x)],null),$CLJS.ms.g(x))}function e(v,x){return $CLJS.m($CLJS.m(v)?x:v)?$CLJS.cn.l($CLJS.G([v,x])):$CLJS.m(v)?v:x}a=$CLJS.m(a)?dL($CLJS.Pp.h(a,c)):null;b=$CLJS.m(b)?dL($CLJS.Pp.h(b,c)):null;var f=$CLJS.m(a)?$CLJS.xp.g(a):null,g=$CLJS.m(b)?$CLJS.xp.g(b):null,l=$CLJS.Qf(c),n=$CLJS.M.j(l,$CLJS.bv,function(v,x){return x}),q=$CLJS.M.j(l,$CLJS.dv,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Gb(function(){var v=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Lj,null,$CLJS.Ul,null],null),null);v=v.g?v.g(f):v.call(null,f);return $CLJS.m(v)?(v=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Lj,null,$CLJS.Ul,null],null),null),v.g?v.g(g):v.call(null,g)):v}()))return n.j?n.j(a,b,c):n.call(null,a,b,c);if($CLJS.F.h(f,$CLJS.Ul)&&$CLJS.F.h(g,$CLJS.Ul)){l=e($CLJS.Ro(a),$CLJS.Ro(b));var u=$CLJS.cj.g($CLJS.oi);a=$CLJS.Sb(function(v,
x){var y=$CLJS.J(x,0,null);if($CLJS.m(function(){var B=$CLJS.r(u);return B.g?B.g(y):B.call(null,y)}()))return $CLJS.Sb(function(B,H){var I=$CLJS.J(H,0,null),Q=$CLJS.kf.h;if($CLJS.F.h(I,y)){I=$CLJS.J(H,0,null);var Y=$CLJS.J(H,1,null),aa=$CLJS.J(H,2,null);$CLJS.J(x,0,null);var ha=$CLJS.J(x,1,null),qa=$CLJS.J(x,2,null);H=bL(H);var Ea=bL(x);H=q.h?q.h(H,Ea):q.call(null,H,Ea);Y=$CLJS.cn.l($CLJS.G([Y,ha]));qa=new $CLJS.S(null,3,5,$CLJS.T,[I,$CLJS.U.j(Y,$CLJS.Rj,$CLJS.Gb(H)),kL.j?kL.j(aa,qa,c):kL.call(null,
aa,qa,c)],null);I=$CLJS.J(qa,0,null);aa=$CLJS.J(qa,1,null);qa=$CLJS.J(qa,2,null);I=$CLJS.Gb(qa)?new $CLJS.S(null,2,5,$CLJS.T,[I,aa],null):$CLJS.m($CLJS.m(aa)?!1===$CLJS.Rj.g(aa)&&$CLJS.F.h(1,$CLJS.E(aa)):aa)?new $CLJS.S(null,2,5,$CLJS.T,[I,qa],null):$CLJS.Gb($CLJS.A(aa))?new $CLJS.S(null,2,5,$CLJS.T,[I,qa],null):!1===$CLJS.Rj.g(aa)?new $CLJS.S(null,3,5,$CLJS.T,[I,$CLJS.Am.h(aa,$CLJS.Rj),qa],null):new $CLJS.S(null,3,5,$CLJS.T,[I,aa,qa],null)}else I=H;return Q.call($CLJS.kf,B,I)},$CLJS.Lg,v);$CLJS.dj.j(u,
$CLJS.kf,y);return $CLJS.kf.h(v,x)},$CLJS.Lg,$CLJS.oh.h($CLJS.To(a),$CLJS.To(b)));return $CLJS.Vr($CLJS.Ul,l,a,c)}return function(v,x){var y=$CLJS.A(v);v=$CLJS.C(y);var B=$CLJS.D(y);y=$CLJS.C(B);B=$CLJS.D(B);var H=$CLJS.A(x);x=$CLJS.C(H);var I=$CLJS.D(H);H=$CLJS.C(I);I=$CLJS.D(I);return $CLJS.Vr($CLJS.Lj,e(v,x),$CLJS.qg.l(new $CLJS.S(null,1,5,$CLJS.T,[kL.j(y,H,c)],null),B,$CLJS.G([I])),c)}(d(f,a),d(g,b))};kL.v=3;
var lL=function lL(a){switch(arguments.length){case 2:return lL.h(arguments[0],arguments[1]);case 3:return lL.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};lL.h=function(a,b){return lL.j(a,b,null)};
lL.j=function(a,b,c){return kL.j(a,b,$CLJS.Nm.j($CLJS.Nm.j(c,$CLJS.bv,$CLJS.cg($CLJS.Ye,function(d,e,f){var g=$CLJS.F.h($CLJS.su.h(d,null),$CLJS.su.h(e,null));return $CLJS.m(g)?d:$CLJS.Pp.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,d,e],null),f)})),$CLJS.dv,$CLJS.cg($CLJS.Ye,function(d,e){return $CLJS.m(d)?e:d})))};lL.v=3;$CLJS.h=fL.prototype;$CLJS.h.O=function(a,b){return new fL(this.Mf,this.gf,this.type,this.min,this.max,this.Le,this.Ob,this.cc,b)};$CLJS.h.N=function(){return this.Ef};$CLJS.h.fb=$CLJS.t;
$CLJS.h.Ya=function(){return this.type};$CLJS.h.Za=function(){return this.Ob};$CLJS.h.Xa=function(a,b,c,d){var e=this;$CLJS.qp(e.type,b,c,e.min,e.max);a=function(){var u=$CLJS.Mg(c);return e.cc.j?e.cc.j(b,u,d):e.cc.call(null,b,u,d)}();var f=$CLJS.J(a,0,null),g=$CLJS.J(a,1,null),l=$CLJS.J(a,2,null),n=new $CLJS.Yi(function(){return $CLJS.Vp(e.type,b,g,d)}),q=$CLJS.Up();return new cL(n,e.gf,d,g,b,e.Le,l,f,e.min,e.Ef,e.Ob,e.cc,this,a,e.Mf,e.type,q,e.max,new $CLJS.k(null,1,[$CLJS.Uk,$CLJS.Br],null))};
$CLJS.mL=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.nL=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);var hL=$CLJS.cj.g($CLJS.cn.l($CLJS.G([$CLJS.Vt(),new $CLJS.k(null,3,[$CLJS.fu,gL(new $CLJS.k(null,2,[$CLJS.Uk,$CLJS.fu,$CLJS.Gt,eL(kL)],null)),$CLJS.Zt,gL(new $CLJS.k(null,2,[$CLJS.Uk,$CLJS.Zt,$CLJS.Gt,eL(lL)],null)),$CLJS.qu,gL(new $CLJS.k(null,5,[$CLJS.Uk,$CLJS.qu,$CLJS.fv,1,$CLJS.ok,2,$CLJS.Vl,2,$CLJS.Gt,function(a,b,c){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nm.j(b,0,function(d){return $CLJS.Pp.h(d,c)}),$CLJS.Nm.j(b,0,function(d){return $CLJS.su.h(d,c)}),$CLJS.R.h(jL,$CLJS.kf.h(b,c))],null)}],
null))],null)])));$CLJS.gg($CLJS.sn,$CLJS.Vn(new aL(hL,$CLJS.P)));